import React from 'react';
import { connect } from 'react-redux';
import { Button, Dialog, FormGroup, notification } from 'tyb';
import { NewSelect } from 'ucode-components';
const { loadEseRelations } = iceStarkData.store.get('commonAction')?.miniProgramEseRelations;
const { loadAddressBookSelections } = iceStarkData.store.get('commonAction')?.addressBooks;
const { getTokenMpuser, updateCurrentAppId } = iceStarkData.store.get('commonAction')?.mpUsers;
const {setNavBar} = iceStarkData.store.get('commonAction')?.ui;
import { formatDate } from 'ucode-utils';
import enums from '@/assets/enum.json';
import urls from '@/api/urls';
import queryString from "query-string";
import './index.scss';
import QRCode from 'qrcode'

@connect(
    state => ({
        ...state.miniProgramEseRelations,
        ...state.addressBooks,
        ...state.mpUsers,
        permissionIds: state.users.permissionIds||[],
    }),
    {
        setNavBar,
        updateCurrentAppId,
        loadEseRelations: loadEseRelations.REQUEST,
        loadAddressBookSelections: loadAddressBookSelections.REQUEST,
        getTokenMpuser: getTokenMpuser.REQUEST,
    }
)
export default class WechatManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showQrcode: false,
            addressbookId: '',
            qrcodePrefix: '', 
            qrcodeImageUrl: '',
            appId: '',
            addressbookIdRule: {error: '', touched: null}
        };
    }
    componentDidMount() {
        // 获取小程序列表
        this.props.loadEseRelations();
        this.props.loadAddressBookSelections ({status: 'ENABLE'});
    }

    componentDidUpdate(preProps) {
        if (preProps.updateTokenLoading && !this.props.updateTokenLoading && preProps.token  != this.props.token) {
            const { qrcodePrefix = '', appId = '' } = this.state;
            console.log('qrUrl', `${ qrcodePrefix }${ appId }/${ this.props.token }`)
            QRCode.toDataURL(`${ qrcodePrefix }${ appId }/${ this.props.token }`, {}, (err, url) => {
                if (err) throw err;
                // this.QRCodeEndTime = new Date().getTime() + 120000;
                this.QRCodeEndTime = new Date().getTime() + 3600000;
                this.setState({ qrcodeImageUrl: url });
            })
        }
    }

    // 渲染列表
    _renderList = () => {
        const { eseRelations = [] } = this.props;
            return eseRelations.map((val, key) =>
            <div className="item" key={ key }>
                <div className="headImage">
                        <img src={ val.headUrl}  alt="小程序头像"/>
                    {/* <img src="https://files.muzli.space/50d7dfd98a20fa4d225fb9f338a126b3.jpeg"  alt="用户头像"/> */}
                </div>
                <div className="authminprogramtools-content">
                    <div className="item-group">
                        <label>小程序</label>
                            <div className="item-control">{val.appId == 'wxac0aa09a80d9e96e' ?  '码查询' : val.name}</div>
                    </div>
                
                        <div >
                        {this.props.permissionIds.includes('data.organization.mpuser.token') && <Button onClick={ () => {
                            this.setState({ showQrcode: true, appId: val.appId, qrcodePrefix: val.qrcodePrefix, addressbookId: '' })
                        } }>扫码开通</Button>}
                        <Button
                            className="weak"
                            onClick={ () => {
                                this.props.updateCurrentAppId({currentAppId: val.appId});
                                this.props.history.push(`dataorganization/mpuser?${ queryString.stringify({ appId: val.appId }) }`);
                                this.props.setNavBar('人员管理')
                            } }>人员管理</Button>
                    </div>
                </div>
            </div>
        );
    }

    handleModalCancel = () => {
        this.setState({ showQrcode: false, qrcodeImageUrl: '', addressBookId:'' });
    }

    handleChangeSelect = value => {
        this.setState({ addressbookId: value, addressbookIdRule: { ...this.state.addressbookIdRule, error: '' } });
        this.props.getTokenMpuser({
            addressBookId: value.value,
        })
    }

    // 刷新二维码
    UpdateQRCode = () => {
        const { addressbookId } = this.state;
        if (!addressbookId.value) { 
            this.setState({ addressbookIdRule: { ...this.state.addressbookIdRule, error: '请选择通讯录' } });
        } else if (new Date().getTime() > this.QRCodeEndTime) {
            this.handleChangeSelect(addressbookId);
        } else {
            notification.warning('二维码未过期，请稍后再试');
        }
    }

    // QRCodeEndTime = new Date().getTime() + 120000;
    QRCodeEndTime = new Date().getTime() + 3600000;
    render() {
        const { showQrcode, addressbookId, qrcodeImageUrl, addressbookIdRule } = this.state;
        const { addressBookSelections } = this.props;
        return (
            <div className="authminprogramtools">
                <div className="content">
                    {this._renderList()}
                </div>
                <Dialog 
                    style={{ width: '420px' }}
                    title="扫码开通小程序"
                    visible={showQrcode} 
                    okText="刷新"
                    cancelText="关闭"
                    onOk={ () => { this.UpdateQRCode()}}
                    onCancel={ this.handleModalCancel }
                    className="qrcode-authminprogramtools"
                    >
                    <div style={ { textAlign: 'center' } }>
                        <FormGroup
                            style={ { width: '250px' } }
                            label="通讯录"
                            required
                            control={ NewSelect }
                            needText
                            value={ addressbookId }
                            list={ addressBookSelections }
                            onChange={ this.handleChangeSelect }
                       
                            text={ addressbookIdRule.error}
                            meta={ addressbookIdRule}
                        />
                       {qrcodeImageUrl &&  <div>
                            <img className="qrcode" src={ qrcodeImageUrl}></img>
                        </div>}
                       {qrcodeImageUrl && <span style={{color: '#888'}}>二维码将于{ formatDate(this.QRCodeEndTime).slice(11, formatDate(this.QRCodeEndTime).length)}后过期</span> }
                    </div>
                </Dialog>
            </div>
        )
    }
}